import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import imageSequence from './imgSequence';
import navWheel from './linkWheel';

gsap.registerPlugin(ScrollTrigger);

const toggleClass = (add: boolean, className: string, el: Element) => {
  if (add) el.classList.add(className)
  else el.classList.remove(className)
}

(function () {
  const captions = document.querySelectorAll('.captions p');

  imageSequence.tl
    .to('#hero h1', { opacity: 0, duration: 1.5 }, '0')
    .to('#scroll-indicator-hero', { autoAlpha: 0, duration: .1 }, '0')
    .to(imageSequence.canvas, { opacity: .2, duration: 2 })
    .addLabel('captions', '<')


  captions.forEach((el, index) => {
    const delay = (index === 0) ? '<' : '>';
    imageSequence.tl
      .fromTo(el, {
        opacity: 0,
        lazy: false,
        ease: 'none',
      }, { opacity: 1, duration: .6, }, delay)
      .from(el, { y: 50, duration: 1, lazy: false, ease: 'none', }, '<')
      .to(el, {
        y: -50,
        duration: 1,
        ease: 'none',
      }, '>')
      .to(el, {
        opacity: 0,
        duration: .6,
        ease: 'none'
      }, '<')
  });
})();

(function () {
  const descriptionElements = document.querySelectorAll('#short-intro span');
  const container = document.querySelector('#short-intro .section-content');
  let highlightIndex = -1

  const tlDescription = ScrollTrigger.create({
    trigger: '#short-intro .section-content',
    start: 'top bottom',
    endTrigger: '#short-intro',
    end: 'bottom center',
    scrub: true,
    onEnter: showContainer,
    onEnterBack: showContainer,
    onLeave: hideContainer,
    onLeaveBack: hideContainer,
    onUpdate: highlight
  }
  );

  function showContainer() { container.classList.add('shown'); }
  function hideContainer() { container.classList.remove('shown'); }


  function highlight(tl) {
    const part = 1 / descriptionElements.length
    const newHighlight = Math.ceil(tl.progress / part) - 1;

    if (newHighlight > descriptionElements.length - 1 || newHighlight < 0) return;

    if (newHighlight !== highlightIndex) {
      highlightIndex = newHighlight
      descriptionElements.forEach(el => el.classList.remove('highlight'));
      descriptionElements[highlightIndex].classList.add('highlight');
    }
  }

})();

let tlTopics;

const initWheel = () => {
  if (window.innerWidth < 767) {
    if (tlTopics && tlTopics.scrollTrigger.enabled) {
      tlTopics.scrollTrigger.disable();
    }

    return;
  }

  if (tlTopics) {
    if (!tlTopics.scrollTrigger.enabled) {
      tlTopics.scrollTrigger.enable();
      tlTopics.scrollTrigger.refresh();
    }
    return;
  }

  gsap.set('#nav-wheel', {
    left: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: 0
  });

  tlTopics = gsap.timeline({
    scrollTrigger: {
      trigger: '#choose-place',
      start: 'top center',
      endTrigger: '.topic',
      end: 'top center',
      scrub: true,
      fastScrollEnd: 3000,
    }
  });

  tlTopics
    .fromTo('#choose-place > div',
      { autoAlpha: 0, lazy: false },
      { autoAlpha: 1, duration: 1 })
    .to('#choose-place > div', { autoAlpha: 0, duration: 1 }, '>+2')
    .to('#nav-wheel', {
      visibility: 'visible',
      opacity: 1,
      duration: 2
    })
    .to(imageSequence.canvas, { opacity: 0, duration: 2 }, '<')
    .to('#nav-wheel', {
      left: 0,
      transform: 'translate(-60%, -50%)',
      duration: 2,
    }, '>2')
    .to(navWheel.links, {
      wheelPos: gsap.utils.wrap([27, 32, 37, 42]),
      duration: 2,
      stagger: -.1,
      ease: 'none',
    }, '<')
    .call(() => {
      let shorten = true
      if (tlTopics.scrollTrigger.direction < 0) shorten = false;
      shortenLinks(shorten)
    }, null, '<20%')

  const topics = document.getElementsByClassName('topic');

  const tlWheel = gsap.timeline({
    scrollTrigger: {
      trigger: topics[0],
      start: 'top center',
      endTrigger: topics[topics.length - 1],
      end: 'bottom top',
      scrub: true
    }
  });

  tlWheel
    .to(navWheel.links, {
      ease: 'linear',
      wheelPos: gsap.utils.wrap([6, 11, 16, 21])
    })
    .to('#nav-wheel', { xPercent: -200, duration: .08 }, '-=100%');

  function shortenLinks(shorten: boolean) {
    const action = (!shorten)
      ? (el) => { el.classList.remove('hide'); }
      : (el) => { el.classList.add('hide'); };

    for (let link of navWheel.links) {
      const toHide = link.el;
      action(toHide);
    }
  }

  const topicTl = (topic: HTMLElement) => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: topic,
        start: 'top 50%',
        end: 'bottom 50%',
        onToggle: activeTopic
      }
    });

    return tl;
  }

  for (const item of topics) {
    topicTl(item as HTMLElement);
  }

  function activeTopic(animation: ScrollTrigger) {
    navWheel.links.forEach(link => link.el.children[0].classList.remove('active'));
    const id = animation.trigger.id;

    if (animation.isActive) {
      const link = navWheel.links.find(link =>
        link.el.querySelector('a').attributes.href.value == `#${id}`
      );
      link.el.children[0].classList.add('active');
    }
  }
}

let aboutAnimation;

const initAbout = () => {
  const about = document.getElementById('about');
  let newWidth = () => document.getElementById('about').scrollWidth;

  if (window.innerWidth < 767) {
    if (aboutAnimation && aboutAnimation.scrollTrigger.enabled) {
      aboutAnimation.scrollTrigger.disable();
    }

    return;
  }

  if (aboutAnimation) {
    if (!aboutAnimation.scrollTrigger.enabled) {
      aboutAnimation.scrollTrigger.enable();
      window.requestAnimationFrame(() => aboutAnimation.scrollTrigger.refresh());
    }

    return;
  }

  aboutAnimation = gsap.timeline({
    scrollTrigger: {
      trigger: about,
      start: "top 70px",
      end: () => `+=${newWidth()}`,
      scrub: true,
      pin: true,
      invalidateOnRefresh: true,
      anticipatePin: 1
    }
  });

  aboutAnimation.to(about, {
    x: () => -(newWidth() - document.documentElement.clientWidth),
    ease: "none",
    duration: 10,
  })
    .to('#scroll-indicator-about', { autoAlpha: 0, duration: .1 }, '0')

  const descriptionanimation = gsap.from('#about-description p', {
    scrollTrigger: {
      trigger: '#about-description',
      containerAnimation: aboutAnimation,
      scrub: true,
      start: 'left 80%',
      end: 'right left',
    },
    lazy: false,
    x: (i) => { return (i == 0) ? '-5vw' : '5vw' },
  });

  gsap.from('#about-whyCampfire p', {
    scrollTrigger: {
      trigger: '#about-whyCampfire',
      start: 'left right',
      end: 'left left',
      containerAnimation: aboutAnimation,
      scrub: true,
    },
    xPercent: i => i * 50,
    lazy: false,
  });

  const processSteps = document.getElementById('about-process').children;
  for (const step of processSteps) {
    gsap.to(step, {
      scrollTrigger: {
        trigger: step,
        start: 'left 70%',
        containerAnimation: aboutAnimation,
        toggleClass: 'active',
        scrub: true,
      },
    });
  }

  const tlHow = gsap.timeline({
    scrollTrigger: {
      trigger: '#about-howWeDoIt',
      start: 'left right',
      end: 'left left',
      containerAnimation: aboutAnimation,
      scrub: true,
    }
  });

  const combineEl = document.querySelector('#about-howWeDoIt .combine');

  tlHow
    .from(combineEl, {
      xPercent: 100,
      lazy: false,
    })
    .call(() => {
      let active = true
      if (tlHow.scrollTrigger.direction < 0) active = false;
      toggleClass(active, 'active', combineEl)
    }, null, '<65%');
}

(function () {
  const helpItems = document.querySelectorAll('.startingpoints__item');

  const handleOver = (item: Element) => {
    item.parentElement.classList.add('has-shown-items');
    item.classList.add('active');
  }

  const handleOut = (item: Element) => {
    item.parentElement.classList.remove('has-shown-items');
    item.classList.remove('active');
  }


  helpItems.forEach((item) => {
    item.addEventListener('mouseover', () => handleOver(item));
    item.addEventListener('mouseout', () => handleOut(item));
  });
})();

(() => {
  const coveredCircles = document.querySelectorAll('#graphic-covered circle');
  const descriptions = document.querySelector('#about-covering .descriptions').children
  const hideDescriptions = () => {
    for (let item of descriptions) {
      (item as HTMLElement).style.maxHeight = '0';
      (item as HTMLElement).style.transform = 'translateX(-100%)';
    }
  }
  const showDescription = (el) => {
    el.style.maxHeight = '500px';
    el.style.transform = 'translateX(0)';
  }

  coveredCircles.forEach((circle) => {
    circle.addEventListener('mouseover', handleCircleClick);
  });

  function handleCircleClick(event) {
    const id = event.target.dataset.target;
    if (id === null) return;

    const description = document.getElementById('desc-' + id);
    coveredCircles.forEach(el => el.classList.remove('active'));
    event.target.classList.add('active');
    hideDescriptions();
    showDescription(description);
  }

  (function () {
    const id = 'software-solution';
    const description = document.getElementById('desc-' + id);
    const circle = document.getElementById('circle-' + id);

    if (!circle || !description) return;
    circle.classList.add('active');
    showDescription(description);
    console.log(description);
  })()
})()

initAbout();
initWheel();

window.addEventListener('resize', () => {
  navWheel.getRadius();
  navWheel.renderLinks();
  initAbout();
});

(function () {
  const cookie = document.cookie;
  if (cookie.indexOf('OptanonAlertBoxClosed') < 0 && cookie.indexOf('OptanonConsent') >= 0) {
    document.documentElement.style.overflow = 'hidden';
  }
})();

(function scrollDown() {
  const duration = 1500;
  const indicator = document.querySelector('.scroll-indicator');
  const destination = imageSequence.tl.scrollTrigger.labelToScroll('captions');
  let start, previousTimestamp, startScroll, step;

  const initScroll = () => {
    startScroll = document.documentElement.scrollTop;
    step = (destination - startScroll) / duration;
    start = undefined;
    document.documentElement.style.scrollBehavior = 'unset'
    window.requestAnimationFrame(smoothScroll);
  }

  const smoothScroll = (timestamp) => {
    if (start === undefined) {
      start = timestamp;
    }

    const elapsed = timestamp - start;

    if (previousTimestamp !== timestamp) {
      document.documentElement.scrollTop = elapsed * step;
    }

    if (elapsed < duration) {
      previousTimestamp = timestamp;
      window.requestAnimationFrame(smoothScroll)
    }
    else {
      document.documentElement.style.scrollBehavior = 'smooth'
    }
  }

  indicator.addEventListener('click', () => {
    initScroll();
  });

})();

(function () {
  const email = 'jkeltsch@deloitte.de,mahorst@deloitte.de'
  const requestDemo = 'Hi Jan-Niklas,\n I came across the CAMPfire landing page. This sounds interesting to me. I would like to have a demo to understand more about the product.\n Thank you'
  const getInTouch = 'Hi Jan-Niklas,\nI came across the CAMPfire landing page. This sounds interesting to me. Can you please reach out to me with some details?\nThank you'


  const gitLink = document.getElementById('get-in-touch');
  const rdLink = document.getElementById('request-demo');

  gitLink.setAttribute('href', `mailto:${email}?subject=CAMPfire&body=${encodeURIComponent(getInTouch)}`);
  rdLink.setAttribute('href', `mailto:${email}?subject=Request CAMPfire demo&body=${encodeURIComponent(requestDemo)}`);
})();
