class WheelLink {
  private _wheelPos;
  pos: { x, y };
  el;
  wheel;
  radiusOffset = 25;

  constructor(el: HTMLElement, wheelPos, wheel) {
    this.wheel = wheel;
    this._wheelPos = wheelPos;
    this.el = el;
    this.pos = this.getPosition();
  }

  getPosition() {
    const position = (this._wheelPos -25) % 100;
    const radian = 2 * Math.PI * (position / 100);
    const x = Math.cos(radian);
    const y = Math.sin(radian);

    return { x, y };
  }

  public render() {
    const x = Math.round(this.pos.x * (this.wheel.radius + this.radiusOffset));
    const y = Math.round(this.pos.y * (this.wheel.radius + this.radiusOffset));

    let offsetX = 0;
    if (!(this.pos.y < -0.9 || this.pos.y > 0.9)) {
      offsetX = (this.pos.x < 0) ? -100 : 0;
    } else { // move around the corner
      offsetX = this.pos.x * 100 / (2 * 0.436) - 50;
    }

    let offsetY = this.pos.y * 50 - 50;

    this.el.style.transform
      = `translate(calc(${x}px + ${offsetX}%), calc(${y}px + ${offsetY}%))`;
  }

  public set wheelPos(value) {
    this._wheelPos = value;
    this.pos = this.getPosition();
    this.render();
  }

  public get wheelPos() {
    return this._wheelPos;
  }
}

class Wheel {
  el;
  links: Array<WheelLink> = [];
  radius;

  constructor() {
    this.el = document.querySelector('.wheel') as HTMLElement
    this.getRadius();
    const links = document.querySelectorAll('#nav-wheel li');
    
    for (let [index,link] of links.entries()) {
      const percent = 100 / links.length * (index) + 12.5;
      this.links.push(new WheelLink(link as HTMLElement, percent, this));
    }

    this.renderLinks();
  }

  getRadius() {
    this.radius = this.el.offsetWidth / 2;
  }

  renderLinks() {
    this.links.forEach((link) => link.render());
  }

}

const navWheel = new Wheel();

export default navWheel;
