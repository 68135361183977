import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

class ImageSequence {
  canvas = <HTMLCanvasElement>document.getElementById('hero-canvas');
  context = this.canvas.getContext('2d');
  frameCount: number = 157;
  height = 1080;
  width = 1920;
  public _currentFrame = 1
  public tl
  frames: Array<HTMLImageElement> = []


  constructor() {
    gsap.registerPlugin(ScrollTrigger);

    this.canvas.width = this.width;
    this.canvas.height = this.height;
    this.frames[0] = new Image()
    this.frames[0].src = this.getFrameUrl(1);
    this.frames[0].onload = () => {
      this.context.drawImage(this.frames[0], 0, 0);
    }
    this.preloadImages();

    this.tl = gsap.timeline({
      scrollTrigger: {
        trigger: '#hero',
        start: 'top top',
        endTrigger: '#short-intro',
        end: 'top center',
        scrub: .5,
      }
    });

    this.tl.to(this, {
      currentFrame: this.frameCount - 1,
      duration: 3,
      ease: 'linear',
    }, '.3')

  }

  private preloadImages() {
    for (let i = 1; i < this.frameCount; i++) {
      const img = new Image();
      img.src = this.getFrameUrl(i);
      this.frames[i] = img
    }
  }

  private getFrameUrl(index: number) {
    const fileNumber = <string>index.toString().padStart(5, '0');
    return `fire_sequence/small/MAIN_Campfire_orange_v2_${fileNumber}.jpg`;
  }

  updateImage = () => {
    const frame = this.frames[(Math.floor(this._currentFrame))];
    this.context.drawImage(frame, 0, 0);
  }

  public set currentFrame(frame) {
    this._currentFrame = frame;
    requestAnimationFrame(this.updateImage);
  }
}

const imageSequence = new ImageSequence();
export default imageSequence;